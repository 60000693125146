import React from "react";
import { ReactComponent as BLogo} from "../assets/b_logo.svg";
import Title from "../components/title";

class HomePage extends React.Component {
	render() {
		return <div>
			<Title></Title>
			<div className={"welcome-section"}>
				<div className={"welcome-navbar"}>
					<div className={"link"}>
						<a href={"your-info"}>
							<span>YOUR INFO</span>
						</a>
					</div>
					<a href={"/"}>
						<BLogo className={"home-logo"}></BLogo>
					</a>
					<div className={"link"}>
						<a href={"binaries"}>
							<span>BINARIES</span>
						</a>
					</div>
				</div>
				<div className={"centered-content"}>
					<h1>The place for tech stuff.</h1>
					<div className={"funnywheel"}></div>
					<p>We like tech, you like tech, so why not be friends?</p>
					<small className={"faded"}>* Butts LLC is not an officially registered company, anywhere, anytime</small>
				</div>
			</div>
		</div>
	}
}

export default HomePage;