import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage";
import BinariesPage from "./pages/binaries";
import YourInfoPage from "./pages/your-info";


class App extends React.Component {

	render() {
		return <BrowserRouter>
			<Routes>
				<Route path={"/"} element={<HomePage />} />
				<Route path={"/binaries"} element={<BinariesPage />} />
				<Route path={"/your-info"} element={<YourInfoPage />} />
			</Routes>
		</BrowserRouter>
	}
}

export default App;
