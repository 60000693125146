import React from "react";
import { ReactComponent as ButtsIconNotRgst } from '../assets/buttsllc.svg';

class Title extends React.Component {
	render() {
		return <div className={"title-section"}>
			<ButtsIconNotRgst className={"logo"}></ButtsIconNotRgst>
		</div>;
	}
}

export default Title;