import React from "react";

class BinariesPage extends React.Component {
	render() {
		return <div className={"in-works"}>
			<h1 className={"cool-bg"} style={{fontSize: "2rem"}}>&lt;/ Nothing here yet&gt;</h1>
		</div>;
	}
}

export default BinariesPage;