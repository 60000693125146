import React from "react";
import axios from "axios";
import { ReactComponent as BLogo } from '../assets/b_logo.svg';
import Loading from "react-loading";
import ReactPlaceholder from "react-placeholder";

interface JumpingTextProps {
	text: string;
}

const JumpingText: React.FC<JumpingTextProps> = ({text}) => {
	let total_index = 0;
	return (
		<h2 className="jumping-text">
			{text.split(' ').map((word, wordIndex) => (
				<span key={wordIndex} className="no-wrap">
          {word.split('').map((letter, letterIndex) => {
			  total_index += 1;
			  return (
				  <span
					  key={total_index}
					  style={{'--i': total_index} as React.CSSProperties}
				  >
                {letter}
              </span>
			  );
		  })}
					{/* Add a non-breaking space after each word except the last one */}
					{wordIndex < text.split(' ').length - 1 && <span> </span>}
        </span>
			))}
		</h2>
	);
};

interface InfoState {
	ip: string;
	loadingIp: boolean;
	continent: string,
	continentCode: string,
	country: string,
	countryCode: string,
	region: string,
	regionCode: string,
	city: string,
	district: string,
	zip: string,
	geolocationLat: string,
	geolocationLon: string,
	timezone: string,
	timeOffset: number,
	currency: string,
	isp: string,
	organisation: string,
	as: string,
	asName: string,
	asReverseRoute: string,
	mobile: boolean,
	proxy: boolean,
	hosting: boolean,
	vpn: string,
	loadingIpData: boolean,
}

interface DataRowProps {
	loading: boolean,
	keyy: string,
	value: string,
}

const DataRow: React.FC<DataRowProps> = ({loading, keyy, value}) => {
	value = value.replace("undefined", "—")
	return <div className={"data-row"}>
		<h4>{keyy}</h4>
		<ReactPlaceholder ready={!loading} showLoadingAnimation={true} type={"textRow"} style={{height: "max(2vw, 2vh)"}}><h4>{value === "" ? "—" : value}</h4></ReactPlaceholder>
	</div>;
}

class YourInfoPage extends React.Component<{}, InfoState> {
	constructor(props: {}) {
		super(props);
		this.state = {
			ip: '',
			loadingIp: true,
			continent: "",
			continentCode: "",
			country: "",
			countryCode: "",
			region: "",
			regionCode: "",
			city: "",
			district: "",
			zip: "",
			geolocationLat: "",
			geolocationLon: "",
			timezone: "",
			timeOffset: 0,
			currency: "",
			isp: "",
			organisation: "",
			as: "",
			asName: "",
			asReverseRoute: "",
			mobile: false,
			proxy: false,
			hosting: false,
			vpn: "",
			loadingIpData: true,
		};
	}

	componentDidMount() {
		this.fetchIp();
	}

	fetchIp = async () => {
		try {
			const response = await axios.get('https://api.ipify.org?format=json');
			let ip = response.data.ip;
			this.setState({ip: ip, loadingIp: false});

			const ipData = await axios.get("https://butts.llc/proxied/ipinfo.php?ip=" + ip)
			let vpn;
			if (ipData.data.hosting && ipData.data.proxy) {
				vpn = "Very Likely";
			} else if (ipData.data.hosting || ipData.data.proxy) {
				vpn = "Possibly";
			} else {
				vpn = "Likely Not";
			}
			this.setState({
				continent: ipData.data.continent,
				continentCode: ipData.data.continentCode,
				country: ipData.data.country,
				countryCode: ipData.data.country,
				region: ipData.data.regionName,
				regionCode: ipData.data.region,
				city: ipData.data.city,
				district: ipData.data.discrict,
				zip: ipData.data.zip,
				geolocationLat: ipData.data.lat,
				geolocationLon: ipData.data.lon,
				timezone: ipData.data.timezone,
				timeOffset: ipData.data.offset,
				currency: ipData.data.currency,
				isp: ipData.data.isp,
				organisation: ipData.data.org,
				as: ipData.data.as,
				asName: ipData.data.asname,
				asReverseRoute: ipData.data.reverse,
				mobile: ipData.data.mobile,
				proxy: ipData.data.proxy,
				hosting: ipData.data.hosting,
				vpn: vpn,
				loadingIpData: false,
			})
		} catch (error) {
			console.error('Error fetching IP address:', error);
			// TODO: Show error
		}
	};

	render() {
		return <div className={"data-collector"}>
			<div className={"home-button"}>
				<a href={"/"}>
					<BLogo></BLogo>
				</a>
			</div>
			<div className={"data-intro-section"}>
				<h1 className={"cool-bg"}>BUTTS.LLC —</h1>
				<h1 className={"cool-bg"}>— DATA COLLECTOR</h1>
				<JumpingText text={"We collect your data like good doggies"}></JumpingText>
				<div className={"spacers"}>
					<div className={"previous small-dot-spacer"}></div>
					<div className={"previous small-dot-spacer"}></div>
					<div className={"previous small-dot-spacer"}></div>
					<div className={"previous small-dot-spacer"}></div>
					<div className={"previous small-dot-spacer"}></div>
					<div className={"long-spacer"}></div>
					<div className={"after small-dot-spacer"}></div>
					<div className={"after small-dot-spacer"}></div>
					<div className={"after small-dot-spacer"}></div>
					<div className={"after small-dot-spacer"}></div>
					<div className={"after small-dot-spacer"}></div>
				</div>
				<p><b>Your data is not actually being collected.</b><br/>We don't collect the shown data.
					The purpose of this site is to show you how much data we <b>could</b> collect, which would probably
					be even more if we would provide you with a service.<br/><br/>
					That said, have <span className={"fun-colors"}>fun</span>at the gallery</p>
			</div>
			<div className={"collected-data"}>
				<div className={"data-ip"}>
					<h2>Your IP Address</h2>
					<ReactPlaceholder ready={!this.state.loadingIp}><h3>{this.state.ip}</h3></ReactPlaceholder>
					<p>What we were able to determine just from this:</p>
					<div className={"data-ip-subinfo"}>
						<DataRow loading={this.state.loadingIpData} keyy={"Continent"}
								 value={`${this.state.continent} (${this.state.continentCode})`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Country"}
								 value={`${this.state.country} (${this.state.countryCode})`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Region"}
								 value={`${this.state.region} (${this.state.regionCode})`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"City"}
								 value={`${this.state.city}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"District"}
								 value={`${this.state.district}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Zip"}
								 value={`${this.state.zip}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Geolocation"}
								 value={`${this.state.geolocationLat} - ${this.state.geolocationLon}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Timezone"}
								 value={`${this.state.timezone} (${(this.state.timeOffset > 0 ? "+" : "") + this.state.timeOffset / 60 / 60}h)`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Currency"}
								 value={`${this.state.currency}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"ISP"}
								 value={`${this.state.isp}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Organisation"}
								 value={`${this.state.organisation}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"AS"}
								 value={`${this.state.as}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"AS Name"}
								 value={`${this.state.asName}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"AS Reverse Route"}
								 value={`${this.state.asReverseRoute}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Network Type"}
								 value={`${this.state.mobile ? "Mobile" : "Non Mobile"}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Proxy"}
								 value={`${this.state.proxy ? "Proxied" : "Non Proxied"}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"Hosting"}
								 value={`${this.state.hosting ? "Hosted" : "Non Hosted"}`}></DataRow>
						<DataRow loading={this.state.loadingIpData} keyy={"VPN"}
								 value={`${this.state.vpn}`}></DataRow>
					</div>
				</div>
				<p>to be expanded...</p>
			</div>
		</div>;
	}
}

export default YourInfoPage;